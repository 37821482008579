import Vue from 'vue'
import VueRouter from 'vue-router'
import SessionService from '../services/SessionService'
import UserService from '../services/UserService'

import Trainings from '../views/Trainings.vue'
import Registration from '../views/Registration.vue'
import Login from '../views/Login.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import NotFound404 from '../views/NotFound404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Trainings',
    component: Trainings
  },
  {
    path: '/register',
    name: 'Registration',
    component: Registration,
    meta: {
      unauthenticated: true,
      authenticated: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      unauthenticated: true,
      authenticated: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      authenticated: false
    }
  },
  {
    path: '*',
    name: 'NotFound404',
    component: NotFound404,
  }
]

const router = new VueRouter({
  mode: 'history',
  // eslint-disable-next-line
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticatedRoute = !Object.prototype.hasOwnProperty.call(to.meta, 'authenticated') ? true : to.meta.authenticated;
  const isUnauthenticatedRoute = !Object.prototype.hasOwnProperty.call(to.meta, 'unauthenticated') ? false : to.meta.unauthenticated;

  SessionService.isAuthenticated().then((isAuthenticated) => {
    if (isAuthenticated) {
      if (isUnauthenticatedRoute) {
        // Go to authentication route index
        return next({ name: 'Trainings' });
      }

      if (!UserService.userIsLoaded()) {
        // Load user
        UserService.fetchLoggedInUser();
      }

      next();
    } else {
      // Is not authenticated
      if (isAuthenticatedRoute) {
        // Go to registration
        return next({ name: 'Registration' });
      }

      next();
    }
  });
});

export default router
