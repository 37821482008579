import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'
import ContextService from "@/services/ContextService";

Vue.config.productionTip = false;

(async () => {
  await ContextService.load();

  Vue.use(VueAxios, axios)

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App)
  }).$mount('#app')
})();