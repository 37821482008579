<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="11" md="7" xl="4">
          <v-card class="elevation-12">
            <HeaderImage />
            <v-card-text class="px-6">
              <h2>{{ $t('registration.title') }}</h2>

              <p class="mt-2">
                {{ $t('registration.in-possession-of-account') }} <router-link :to="{ name: 'Login' }">{{ $t('registration.in-possession-of-account-link')}}</router-link>
              </p>

              <v-form v-if="!isFormSubmitted" v-model="isFormValid" v-on:submit="onFormSubmit" ref="form" class="mb-2">
                <p v-if="emailExists" class="well well-info mb-4"><v-icon>mdi-information-variant</v-icon> {{ $t('registration.email-exists') }} <router-link :to="{ name: 'Login', query: { email: email }}">{{ $t('registration.email-exists-link') }}</router-link></p>
                <p v-else-if="formError" class="well well-info mb-4">{{formError}}</p>

                <v-text-field v-model="name" ref="formName" type="text" prepend-icon="mdi-account-outline" :label="$t('registration.input-field-name')" :disabled="isSubmittingForm" :rules="nameRules" :validate-on-blur="!isFormNameValid" required></v-text-field>

                <v-text-field v-model="email" ref="formEmail" type="email" prepend-icon="mdi-email-outline" :label="$t('registration.input-field-email')" :disabled="isSubmittingForm" :rules="emailRules" :validate-on-blur="!isFormEmailValid" required></v-text-field>

                <v-text-field v-model="password" ref="formPassword" type="password" prepend-icon="mdi-fingerprint" :label="$t('registration.input-field-password')" :disabled="isSubmittingForm" :rules="passwordRules" :validate-on-blur="!isFormPasswordValid" required></v-text-field>

                <v-checkbox v-model="agreedWithPrivacyPolicy" color="primary" :disabled="isSubmittingForm" required>
                  <span slot="label">{{ $t('registration.privacy-statement-agreement-1') }} <router-link :to="{ name: 'PrivacyPolicy' }">{{ $t('registration.privacy-statement-agreement-link') }}</router-link> {{ $t('registration.privacy-statement-agreement-2') }}</span>
                </v-checkbox>

                <div>
                  <v-btn type="submit" color="primary" depressed large block :disabled="!isFormValid || !agreedWithPrivacyPolicy || isSubmittingForm || isFormSubmitted">{{ $t('registration.registration-action-label') }}</v-btn>
                  <div class="mt-4 mb-1 text-center">
                    {{ $t('registration.in-possession-of-account') }} <router-link :to="{ name: 'Login' }">{{ $t('registration.in-possession-of-account-link') }}</router-link>
                  </div>
                </div>
              </v-form>
              <div v-else>
                <p class="well well-info icon-left">
                  <v-icon large>mdi-email-outline</v-icon>
                  <span>{{ $t('registration.registration-confirmation') }}</span>
                </p>
                <v-btn text large block color="primary" class="mt-2" :to="{ name: 'Login', query: { email: email }}">{{ $t('registration.login-link-label') }}</v-btn>
              </div>
            </v-card-text>

            <v-progress-linear v-if="isSubmittingForm" indeterminate color="primary"></v-progress-linear>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import HeaderImage from '@/components/HeaderImage';
import AxiosApi from '@/services/AxiosApi';
import UserService from '@/services/UserService';

export default {
  components: {
    HeaderImage,
  },

  watch: {
    name () {
      this.isFormNameValid = this.$refs.formName.validate();
    },
    email (email) {
      this.isFormEmailValid = this.$refs.formEmail.validate();

      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
        this.checkIfEmailExists(email);
      }
    },
    password () {
      this.isFormPasswordValid = this.$refs.formPassword.validate();
    }
  },

  data: () => ({
    name: null,
    email: null,
    password: null,
    agreedWithPrivacyPolicy: false,
    isFormValid: false,
    isSubmittingForm: false,
    isFormSubmitted: false,
    emailExists: false,
    formError: null,
    isFormNameValid: false,
    isFormEmailValid: false,
    isFormPasswordValid: false,
    nameRules: [
      (value) => {
        return !!(value && 5 < value.length && value.indexOf(' ')) || window.translate('registration.input-field-name-validation-message');
      },
    ],
    emailRules: [
      (value) => {
        return /^[a-zA-Z0-9_.+-]+@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value) || window.translate('registration.input-field-email-validation-message');
      },
    ],
    passwordRules: [
      (value) => {
        return /^(?=.*[a-z])(?=.*)(?=.*\d).{8,100}$/.test(value) || window.translate('registration.input-field-password-validation-message');
      },
    ],
  }),

  created () {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'email')) {
      this.email = this.$route.query.email;
    }
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'name')) {
      this.email = this.$route.query.name;
    }
  },

  destroyed () {
    this.email = null;
    this.emailExists = false;
    this.password = null;
  },

  methods: {
    onFormSubmit (e) {
      if (e) {
        e.preventDefault();
      }

      this.formError = null;
      this.$refs.form.validate();

      if (!this.isFormValid) {
        return;
      }

      this.isSubmittingForm = true;

      UserService.unloadUser();

      UserService.register({
        name: this.name,
        email: this.email,
        plainPassword: this.password,
      }).then(() => {
        this.isFormSubmitted = true;
      }).catch((error) => {
        this.formError = error.response;
        window.console.log(error, error.response);
      }).finally(() => {
        this.isSubmittingForm = false;
      });

      return false;
    },

    checkIfEmailExists(email) {
      AxiosApi.request({
        url: 'user-exists',
        method: 'post',
        data: {
          email: email,
        }
      }).then((response) => {
        this.emailExists = response.data.exist;
      });
    },
  }
};
</script>

<style scoped>
.v-input--selection-controls.v-input--checkbox {
  margin-top: 10px;
}
</style>