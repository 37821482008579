<template>
  <v-menu v-if="loggedInUser" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text>
        <v-icon class="mr-2" size="x-large">mdi-account-circle</v-icon>
        <span class="hidden-xs-only">{{loggedInUser.name}}</span>
      </v-btn>
    </template>
    <v-list>
      <v-list-item class="hidden-sm-and-up">
        <v-list-item-title>
          <v-icon>mdi-account-circle</v-icon>
          {{loggedInUser.name}}
        </v-list-item-title>
      </v-list-item>

      <template v-if="!isPartialUser">
        <v-list-item v-on:click="onShowAccountModalClick()">
          <v-list-item-title>
            <v-icon>mdi-account</v-icon>
            {{ $t('global.my-account-link-label') }}
          </v-list-item-title>
        </v-list-item>
      </template>

      <v-list-item  v-on:click="onLogoutClick()">
        <v-list-item-title>
          <v-icon>mdi-logout</v-icon>
          {{ $t('global.logout-action-label') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'AccountMenu',

  props: {
    loggedInUser: Object
  },

  computed: {
    isPartialUser() {
      return this.loggedInUser && this.loggedInUser.isPartialUser;
    },
  },

  methods: {
    onLogoutClick() {
      this.$emit('onLogoutClick');
    },
    onShowAccountModalClick() {
      this.$emit('onShowAccountModalClick');
    }
  }
}
</script>