<template>
  <v-dialog v-model="_isVisible" max-width="400">
    <v-card>
      <v-card-title class="headline">{{ $t('global.my-account-link-label') }}</v-card-title>
      <v-card-text>
        <template v-if="!isChangingName && !isChangingPassword">
          <h3>{{ $t('global.account-name-label') }}</h3>
          <p class="fs-18" v-if="loggedInUser">
            {{loggedInUser.name}}
            <v-btn color="primary" text @click="onChangeNameClick">{{ $t('global.account-name-change-action-label') }}</v-btn>
          </p>

          <template v-if="canChangePassword">
            <h3>{{ $t('global.account-password-label') }}</h3>
            <p class="fs-18">
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
              <v-btn color="primary" text @click="onChangePasswordClick">{{ $t('global.account-password-change-action-label') }}</v-btn>
            </p>
          </template>
        </template>
        <template v-else>
          <template v-if="isChangingName">
            <v-text-field v-model="newName" ref="formName" type="text" prepend-icon="mdi-account-outline" :label="$t('registration.input-field-name')" :disabled="isSubmitting" :rules="nameRules" :validate-on-blur="!isFormNameValid" required></v-text-field>
          </template>
          <template v-if="isChangingPassword">
            <v-text-field v-model="newPassword" ref="formPassword" type="password" prepend-icon="mdi-fingerprint" :label="$t('registration.input-field-password')" :disabled="isSubmitting" :rules="passwordRules" :validate-on-blur="!isFormPasswordValid" required></v-text-field>
            <label>{{ $t('global.password-requirements') }}</label>
          </template>
        </template>
      </v-card-text>

      <v-card-actions v-if="!isChangingName && !isChangingPassword">
        <v-btn color="primary" block text @click="hide()">{{ $t('global.close-menu') }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn color="primary" text @click="onCancelChangeAccount">{{ $t('global.change-account-name-cancel-action-label') }}</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="onChangeClick" :disabled="!(isFormNameValid || isFormPasswordValid) || isSubmitting">{{ $t('global.change-account-name-confirm-action-label') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UserService from "@/services/UserService";

export default {
  name: 'AccountModal',

  props: {
    isVisible: Boolean,
    loggedInUser: Object
  },

  data: () => ({
    isChangingName: false,
    isChangingPassword: false,
    isFormNameValid: false,
    isFormPasswordValid: false,
    isSubmitting: false,
    newName: '',
    newPassword: '',
    nameRules: [
      (value) => {
        return !!(value && 5 < value.length && value.indexOf(' ')) || window.translate('registration.input-field-name-validation-message');
      },
    ],
    passwordRules: [
      (value) => {
        return /^(?=.*[a-z])(?=.*)(?=.*\d).{8,100}$/.test(value) || window.translate('registration.input-field-password-validation-message');
      },
    ],
  }),

  computed: {
    _isVisible: {
      get () {
        return this.isVisible;
      },
      set (visible) {
        if (!visible) {
          this.hide();
        }
      }
    },
    canChangePassword() {
      return this.loggedInUser && this.loggedInUser.email.includes('@');
    },
  },

  watch: {
    newName () {
      this.$nextTick(() => {
        if (!this.$refs['formName'] || !this.isChangingName) {
          this.isFormNameValid = false;
        } else {
          this.isFormNameValid = this.$refs.formName.validate();
        }
      });
    },
    newPassword () {
      this.$nextTick(() => {
        if (!this.$refs['formPassword'] || !this.isChangingPassword) {
          this.isFormPasswordValid = false;
        } else {
          this.isFormPasswordValid = this.$refs.formPassword.validate();
        }
      });
    },
  },

  methods: {
    hide() {
      this.$emit('onClose');
    },
    onChangeNameClick() {
      this.newName = this.loggedInUser.name;
      this.isChangingName = true;
    },
    onChangePasswordClick() {
      this.newPassword = '';
      this.isChangingPassword = true;
    },
    onCancelChangeAccount() {
      this.isChangingName = false;
      this.isChangingPassword = false;
      this.newName = '';
      this.newPassword = '';
    },
    async onChangeClick() {
      if (this.isSubmitting) {
        return;
      }

      this.isSubmitting = true;

      if (this.isChangingName) {
        await UserService.updateName(this.newName);

        this.isSubmitting = false;
        this.newName = '';
        this.isChangingName = false;
      } else if (this.isChangingPassword) {
        await UserService.updatePass(this.newPassword);

        this.isSubmitting = false;
        this.newPassword = '';
        this.isChangingPassword = false;
      }
    },
  }
}
</script>