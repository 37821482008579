import Vue from 'vue'
import Vuex from 'vuex'
import AxiosApi from "@/services/AxiosApi";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    oauth: null,
    loggedInUser: null,
    userIsAnalyst: false,
    context: null,
    language: null,
    region: null,
    trainings: [],
  },
  getters: {
    loggedInUser: (state) => () => {
      return state.loggedInUser;
    },
    userIsAnalyst: (state) => () => {
      return state.userIsAnalyst;
    },
    oauth: state => {
      return state.oauth;
    },
    language: state => {
      return state.language;
    },
    region: state => {
      return state.region;
    },
    getTrainings: (state) => (isAvailable) => {
      let trainings = [];

      Object.keys(state.trainings).forEach((key) => {
        trainings = trainings.concat(state.trainings[key].filter((training) => {
          return training.language === state.language && training.region === state.region && training.isAvailable === isAvailable;
        }));
      });

      return trainings;
    },
    clientId: (state) => {
      return state.context.clientId;
    },
    clientSecret: (state) => {
      return state.context.clientSecret;
    },
  },
  mutations: {
    unloadUser(state) {
      state.loggedInUser = null;
    },
    setOauth(state, oauth) {
      state.oauth = oauth;
    },
    setLoggedInUser(state, user) {
      user['sso'] = !user.email.includes('@');
      state.userIsAnalyst = user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_ANALYST');
      state.loggedInUser = user;
    },
    setLanguage(state, language) {
      state.language = language;
    },
    setRegion(state, region) {
      state.region = region;
    },
    setTrainings(state, trainings) {
      state.trainings = trainings;
    },
    setContext(state, context) {
      state.context = context;
    }
  },
  actions: {
    unloadUser() {
      this.commit('unloadUser');
    },
    setOAuth(state, oauth) {
      if (oauth && Object.prototype.hasOwnProperty.call(oauth, 'access_token')) {
        AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${oauth.access_token}`;
      }
      this.commit('setOauth', oauth);
    },
    setLoggedInUser(state, user) {
      this.commit('setLoggedInUser', user);
    },
    setLanguage(state, language) {
      this.commit('setLanguage', language);
    },
    setRegion(state, region) {
      this.commit('setRegion', region);
    },
    setTrainings(state, trainings) {
      this.commit('setTrainings', trainings);
    },
    setContext(state, context) {
      this.commit('setContext', context);
    },
  }
})
