import store from '../store';
import AxiosApi from "@/services/AxiosApi";
import ContextService from "@/services/ContextService";

const UserService = {
    _isLoading: false,
    fetchUserPromise: null,

    userIsLoaded() {
        return !!store.state.loggedInUser;
    },

    fetchLoggedInUser() {
        this._isLoading = true;

        AxiosApi.request({
            url: 'api/users/@me',
            method: 'get'
        }).then((data) => {
            store.dispatch('setLoggedInUser', data.data.data.attributes);

            return new Promise((resolve) => {
                resolve(store.state.loggedInUser);
            });
        }).finally(() => {
            this._isLoading = false;
        });
    },

    async getLoggedInUser() {
        if (true === this._isLoading) {
            return this.fetchUserPromise;
        } else {
          return new Promise((resolve) => {
              resolve(store.state.loggedInUser);
          });
        }
    },

    unloadUser () {
        store.dispatch('unloadUser');
    },

    register(data) {
        data.client = ContextService.getClientId();

        return AxiosApi.request({
            url: '/api/users',
            method: 'post',
            data: data,
        });
    },

    async updateName(newName) {
        return AxiosApi.request({
            url: '/api/update-name',
            method: 'post',
            data: {
                name: newName,
            }
        }).then(() => {
            store.state.loggedInUser.name = newName;
        });
    },

    async updatePass(newPassword) {
        return AxiosApi.request({
            url: '/api/update-password',
            method: 'post',
            data: {
                password: newPassword,
            }
        });
    }
};

export default UserService;
