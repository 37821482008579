<template>
  <v-app>
    <v-app-bar v-if="loggedInUser" app>
      <v-img alt="VVCR Prodrive" max-height="44" max-width="300" contain transition="scale-transition" :src="require('@/assets/vvcr-logo.png')" />

      <v-spacer></v-spacer>

      <v-btn v-if="userIsAnalyst" :href="analyticsUrl" target="_blank" text outlined class="mr-4">
        {{ $t('global.analytics') }}
      </v-btn>

      <LanguageSelector v-bind:language="$store.state.language" @onSelect="onLanguageSelect" />
      <RegionSelector v-bind:region="$store.state.region" @onSelect="onRegionSelect" />

      <account-menu v-if="loggedInUser" v-bind:logged-in-user="loggedInUser" @onLogoutClick="logout()" @onShowAccountModalClick="isShowingAccountModal = true" />
    </v-app-bar>

    <v-app-bar v-else app color="transparent" class="pr-md-6">
      <v-spacer></v-spacer>

      <LanguageSelector v-bind:language="$store.state.language" @onSelect="onLanguageSelect" />
      <RegionSelector v-bind:region="$store.state.region" @onSelect="onRegionSelect" />
    </v-app-bar>

    <router-view></router-view>

    <account-modal v-bind:is-visible="isShowingAccountModal" v-bind:logged-in-user="loggedInUser" @onClose="isShowingAccountModal = false" />
  </v-app>
</template>

<script>
import AccountModal from "@/components/AccountModal";
import AccountMenu from "@/components/AccountMenu";
import LanguageSelector from "@/components/LanguageSelector";
import RegionSelector from "@/components/RegionSelector";
import SessionService from "@/services/SessionService";
import ContextService from "@/services/ContextService";

export default {
  name: 'App',

  components: {
    AccountModal,
    AccountMenu,
    LanguageSelector,
    RegionSelector
  },

  mounted() {
    // eslint-disable-next-line
    ContextService.selectLanguage(localStorage['language'] || process.env.VUE_APP_I18N_LOCALE || 'nl');
    ContextService.selectRegion(localStorage['region'] || 'nl');

    window.translate = this.$t.bind(this);
  },

  data: () => ({
    isShowingAccountModal: false,
  }),

  computed: {
    loggedInUser() {
      return this.$store.state.loggedInUser;
    },
    userIsAnalyst() {
      return this.$store.state.userIsAnalyst;
    },
    analyticsUrl() {
      return `https://analytics.${window.location.hostname}`;
    }
  },

  methods: {
    logout() {
      SessionService.invalidateSession();

      this.$router.push({name: 'Login'});
    },
    onLanguageSelect(language) {
      ContextService.selectLanguage(language);
    },
    onRegionSelect(region) {
      ContextService.selectRegion(region);
    }
  }
};
</script>

<style lang="scss">
html, body {
  height: 100%;
  overflow: hidden !important;
}
@media screen and (min-width: 960px) {
  main.v-main {
    padding-top: 0 !important;
  }
}
.container.fill-height > .row {
  max-width: 100% !important;
  margin-left: 0;
  margin-right: 0;
}
body > .v-application {
  overflow-y: scroll;
  height: 100%;
  background: url('~@/assets/background.jpg') no-repeat center center !important;
  background-size: cover !important;
}

header.transparent {
  box-shadow: none !important;
}

.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
  line-height: 3 !important;
}
.well {
  padding: 12px;
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  background: #fbfbfb;
  margin: 12px 0 10px;
  font-size: 15px;
  animation: fadein .5s;
  -moz-animation: fadein .5s; /* Firefox */
  -webkit-animation: fadein .5s; /* Safari and Chrome */
  -o-animation: fadein .5s; /* Opera */
}
.well-info {
  background: #e3e8ee;
}
.well i.v-icon {
  margin-top: -1px;
}
p.icon-left {
  position: relative;
  padding-left: 72px;
}
p.icon-left i.v-icon {
  font-size: 36px;
  position: absolute;
  left: 16px;
  top: 50%;
  margin-top: -18px;
}

.vp-outro-wrapper {
  display: none !important;
}

.underline {
  text-decoration: underline;
}

.pr-20 {
  padding-right: 20px;
}
.mt--20 {
  margin-top: -20px;
}
.fs-18 {
  font-size: 18px;
}

.powered-by {
  font-size: 12px;
  opacity: 0.9;
}
.powered-by span {
  display: inline-block;
  padding-bottom: 2px;
}

.v-input--selection-controls .v-input__control {
  width: 100%;
}

@keyframes fadein {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
    opacity:0;
  }
  to {
    opacity: 1;
  }
}
</style>
