<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="6" lg="6" xl="6">
          <v-card class="elevation-12">
            <HeaderImage/>
            <v-card-text style="max-height: 100%" scrollable class="px-6 overflow-y-auto">
              <h1 class="mb-4">{{ $t('404.title') }}</h1>
              <p>
                {{ $t('404.content') }}<br />
                <router-link :to="{ name: 'chapters' }">{{ $t('404.navigate-to-start-link-label') }}</router-link>
              </p>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" block x-large text :to="{ name: 'chapters' }">
                {{ $t('404.navigate-to-start-link-label-secondary') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import HeaderImage from '@/components/HeaderImage';

export default {
  components: {
    HeaderImage,
  },
};
</script>

<style scoped>
h1 {
  font-size: 26px;
}
h2 {
  font-size: 18px;
}
img {
  padding: 24px 0 30px;
}
</style>
