<template>
  <v-main>
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="6" xl="4">
          <v-card class="elevation-12">
            <HeaderImage/>
            <v-card-text class="px-6">
              <h1>{{ $t('login.title') }}</h1>

              <p class="mt-2">
                {{ $t('login.section-1-1') }} <router-link :to="{ name: 'Registration' }">{{ $t('login.section-1-link') }}</router-link> {{ $t('login.section-1-2') }}
              </p>

              <v-form ref="form" v-model="isFormValid" v-on:submit="onFormSubmit" class="mb-2">
                <p class="well well-info mb-4" v-if="formError">{{formError}}</p>

                <v-text-field v-model="email" ref="formEmail" type="email" prepend-icon="mdi-email-outline" :label="$t('login.input-field-email')" :disabled="isSubmittingForm" :rules="emailRules" :validate-on-blur="!isFormEmailValid" required></v-text-field>

                <v-text-field v-model="password" type="password" prepend-icon="mdi-fingerprint" :label="$t('login.input-field-password')" :disabled="isSubmittingForm" validate-on-blur required></v-text-field>

                <div class="mt-1">
                  <v-btn type="submit" depressed color="primary" large block :disabled="!isFormValid || !password || isSubmittingForm || isFormSubmitted">{{ $t('login.login-form-submit-action-label') }}</v-btn>
                  <div class="mt-4 mb-1 text-center">
                    <a v-on:click="isShowingForgotPasswordDialog = true" href="javascript:void(0);">{{ $t('login.password-forgot-label') }}</a>
                    <span class="divider">|</span>
                    <router-link :to="{ name: 'Registration' }">{{ $t('login.registration-link-label') }}</router-link>
                  </div>
                </div>
              </v-form>
            </v-card-text>

            <v-progress-linear v-if="isSubmittingForm" indeterminate color="primary"></v-progress-linear>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <password-reset-modal v-bind:is-visible="isShowingForgotPasswordDialog" @onClose="isShowingForgotPasswordDialog = false" />
  </v-main>
</template>

<script>
import HeaderImage from '@/components/HeaderImage';
import PasswordResetModal from '@/components/PasswordResetModal';
import SessionService from '@/services/SessionService';
import UserService from '@/services/UserService';

export default {
  components: {
    HeaderImage,
    PasswordResetModal
  },
  watch: {
    email () {
      this.isFormEmailValid = this.$refs.formEmail.validate();
    },
  },
  data: () => ({
    name: null,
    email: null,
    password: null,
    isFormValid: false,
    isSubmittingForm: false,
    isFormSubmitted: false,
    isFormEmailValid: false,
    formError: null,
    isShowingForgotPasswordDialog: false,
    emailRules: [
      (value) => {
        return /^[a-zA-Z0-9_.+-]+@\w+([.-]?\w+)*(.\w{2,3})+$/.test(value) || window.translate('login.input-field-email-validation-message');
      },
    ],
  }),

  created () {
    if (Object.prototype.hasOwnProperty.call(this.$route.query, 'email')) {
      this.email = this.$route.query.email;
    }
  },

  destroyed () {
    this.email = null;
    this.password = null;
  },

  methods: {
    onFormSubmit (e) {
      if (e) {
        e.preventDefault();
      }

      this.formError = null;
      this.$refs.form.validate();

      if (!this.isFormValid) {
        return;
      }

      this.isFormSubmitted = false;
      this.isSubmittingForm = true;

      UserService.unloadUser();

      SessionService.authenticate(this.email, this.password).then((response) => {
        this.isFormSubmitted = true;
        SessionService._setOAuth(response.data);
        this.$router.push({ name: 'Trainings' });
      }).catch((error) => {
        this.isFormSubmitted = false;

        const t = this.$t.bind(this);

        switch (error.response.data.error) {
          case 'invalid_grant':
            if ('User is not activated.' === error.response.data.error_description) {
              this.formError = t('login.login-error-email-unvalidated');
            } else if ('Invalid username and password combination.' === error.response.data.error_description) {
              this.formError = t('login.login-error-invalid-password');
            }
            break;
          default:
            this.formError = t('login.login-error-unknown');
        }
      }).finally(() => {
        this.isSubmittingForm = false;
      });

      return false;
    },
  }
};
</script>

<style scoped>
span.divider {
  padding: 0 8px;
  display: inline-block;
}
</style>