import store from '@/store';
import AxiosApi from '@/services/AxiosApi';
import UserService from '@/services/UserService';
import ContextService from '@/services/ContextService';

export default {
    localStorageKey: 'oauth-session',

    async isAuthenticated () {
        return this.getAccessToken().then((result) => {
            let isAuthenticated = false;

            if (result) {
                isAuthenticated = true;
            }

            return new Promise((resolve) => {
                resolve(isAuthenticated);
            });
        });
    },

    async authenticate (email, pass) {
        return AxiosApi.request({
            url: '/oauth/v2/token',
            method: 'post',
            data: {
                grant_type: 'password',
                username: email,
                password: pass,
                client_id: ContextService.getClientId(),
                client_secret: ContextService.getClientSecret(),
            }
        });
    },

    invalidateSession () {
        this._setOAuth(null);
        UserService.unloadUser();
    },

    async getAccessToken () {
        let oauth = this._getOAuth();

        if (!oauth) {
            // Not authenticated
            return new Promise((resolve) => {
                resolve(false);
            });
        }

        // Check if token is expired
        if (this._isExpired(oauth)) {
            // Is expired, refresh token
            this._setOAuth(null);

            return this._getToken(oauth.refresh_token, 'refresh_token');
        }

        // Token is still valid
        return new Promise((resolve) => {
            resolve(oauth.access_token);
        });
    },

    async _getToken(token, grantType) {
        let data = {
            grant_type: grantType,
            client_id: ContextService.getClientId(),
            client_secret: ContextService.getClientSecret(),
        };

        if ('refresh_token' === grantType) {
            data['refresh_token'] = token;
        } else if ('access_token' === grantType) {
            data['access_token'] = token;
        }

        return AxiosApi.request({
            url: '/oauth/v2/token',
            method: 'post',
            data: data,
        }).then((result) => {
            this._setOAuth(result.data);

            return new Promise((resolve) => {
                resolve(result.data.access_token);
            });
        }).catch(() => {
            this._setOAuth(null);

            return new Promise((resolve) => {
                resolve(false);
            });
        });
    },

    _getOAuth () {
        // Check in the Store
        let oauth = store.getters.oauth;

        if (!oauth) {
            // Check in localStorage
            let hash = localStorage.getItem(this.localStorageKey);

            // Check if localStorage contains oauth
            if (hash && 'null' !== hash && undefined !== hash && '' !== hash) {
                oauth = JSON.parse(hash);
            }

            if (!oauth) {
                return false;
            }

            this._setOAuth(oauth);
        }

        return oauth;
    },

    _setOAuth (oauth) {
        if (oauth && Object.prototype.hasOwnProperty.call(oauth,'expires_in') && Object.prototype.hasOwnProperty.call(oauth, 'access_token')) {
            // Token is valid
            if (!Object.prototype.hasOwnProperty.call(oauth, 'expires_at')) {
                // Token is new, so set expires_at
                oauth.expires_at = Math.round(Date.now() / 1000) + oauth.expires_in;
            }

            AxiosApi.defaults.headers.common['Authorization'] = `Bearer ${oauth.access_token}`;
            localStorage.setItem('oauth-session', JSON.stringify(oauth));
        } else {
            AxiosApi.defaults.headers.common['Authorization'] = '';
            localStorage.setItem('oauth-session', null);
        }

        store.dispatch('setOAuth', oauth);
    },

    _isExpired (oauth) {
        return Math.round(Date.now() / 1000) > oauth.expires_at;
    }
}