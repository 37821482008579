import store from '@/store'
import AxiosApi from '@/services/AxiosApi'
import i18n from '@/i18n'

export default {
    languageRegionsOptions: {
        'nl': ['nl'],
        'be': ['be'],
        'fr': ['be'],
        'en': ['nl', 'be'],
    },
    regionLanguagesOptions: {
        'nl': ['nl', 'en'],
        'be': ['be', 'fr', 'en'],
    },

    selectLanguage(language) {
        if (!this.languageRegionsOptions[language].includes(store.getters.region)) {
            let region = this.languageRegionsOptions[language][0];
            store.dispatch('setRegion', region);
            localStorage['region'] = region;
        }

        store.dispatch('setLanguage', language);
        i18n.locale = language;
        localStorage['language'] = language;
    },

    selectRegion(region) {
        if (!this.regionLanguagesOptions[region].includes(store.getters.language)) {
            let language = this.regionLanguagesOptions[region][0];
            store.dispatch('setLanguage', language);
            i18n.locale = language;
            localStorage['language'] = language;
        }

        store.dispatch('setRegion', region);
        localStorage['region'] = region;
    },

    async load() {
        let domain = window.location.hostname;

        if ('localhost' === domain) {
            domain = 'https://raas.vvcr-prodrive.nl';
        }

        const context = await AxiosApi.get('/context', {
            params: {
                domain: domain,
            }
        });

        await store.dispatch('setContext', context.data);
    },

    getOrganisation() {
        return store.getters.organisation.toLowerCase().replace(' ', '-');
    },

    getClientId() {
        return store.getters.clientId;
    },

    getClientSecret() {
        return store.getters.clientSecret;
    },
}