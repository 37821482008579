<template>
  <v-main>
    <v-container class="pt-md-16" fluid>
      <v-row align="start" justify="center" class="mt-6">
        <v-col cols="12" sm="11" md="10" xl="6">
          <v-row align="center" justify="start">
            <template v-for="training in availableTrainings">
              <v-col cols="12" sm="6" md="6" lg="6" xl="4" :key="training.id">
                <TrainingCard v-bind:training="training" v-bind:token="token"/>
              </v-col>
            </template>
          </v-row>

          <v-divider class="my-6" v-if="0 < nonAvailableTrainings.length && 0 < availableTrainings.length"></v-divider>

          <v-row align="center" justify="start">
            <template v-for="training in nonAvailableTrainings">
              <v-col cols="12" sm="6" md="6" lg="6" xl="4" :key="training.id">
                <TrainingCard v-bind:training="training" v-bind:token="token"/>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="0 === nonAvailableTrainings.length && 0 === availableTrainings.length" align="start" justify="center" class="mt-6">
        <v-col cols="12" sm="12" md="6" xl="6">
          <v-card class="my-2">
            <v-card-title>
              <span class="font-weight-light">{{ $t('training.no-trainings-available-title') }}</span>
            </v-card-title>
            <v-card-subtitle>{{ $t('training.no-trainings-available-message') }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import TrainingCard from '@/components/TrainingCard';
import TrainingsProviderService from "@/services/TrainingsProviderService";

  export default {
    name: 'Trainings',

    components: {
      TrainingCard,
    },

    data: () => ({
      onResizeTimeout: null,
      resizeCardsSingleTimeout: null,
      resizeCardsInterval: null,
    }),

    mounted() {
      TrainingsProviderService.getTrainings().then((trainings) => {
        this.$store.dispatch('setTrainings', trainings);
      });

      window.addEventListener('resize', this.onResize);

      this.resizeCardsInterval = setInterval(() => {
        this.resizeCards();
      }, 1000);
      this.resizeCardsSingleTimeout = setTimeout(() => {
        this.resizeCards();
      }, 100);

      this.resizeCards();
    },

    beforeDestroy: function () {
      window.removeEventListener('resize', this.onResize);
      clearInterval(this.resizeCardsInterval);
      clearTimeout(this.resizeCardsSingleTimeout);
    },

    computed: {
      availableTrainings() {
        return this.$store.getters.getTrainings(true);
      },
      nonAvailableTrainings() {
        return this.$store.getters.getTrainings(false);
      },
      token() {
        let oauth = this.$store.getters.oauth;
        return oauth ? oauth.access_token : '';
      }
    },

    methods: {
      onResize() {
        if (!this.onResizeTimeout) {
          this.onResizeTimeout = setTimeout(() => {
            this.resizeCards();
            this.onResizeTimeout = null;
          }, 50);
        }
      },

      resizeCards() {
        let cardElements = document.getElementsByClassName('card-training');
        let cardSize,
            largestCardSize = 0;

        if (600 <= window.outerWidth) {
          for (let i = 0; i < cardElements.length; i++) {
            cardSize = 0;
            cardSize += cardElements[i].querySelector('.v-image').offsetHeight;
            cardSize += cardElements[i].querySelector('.v-card__title').offsetHeight;
            cardSize += cardElements[i].querySelector('.v-card__subtitle').offsetHeight;
            cardSize += cardElements[i].querySelector('.v-card__actions').offsetHeight;

            cardSize -= 30;

            if (largestCardSize < cardSize) {
              largestCardSize = cardSize;
            }
          }
        }

        for (let i = 0; i < cardElements.length; i++) {
          cardElements[i].style.minHeight = largestCardSize + 'px';
        }
      },
    }
  }
</script>
