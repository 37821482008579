import AxiosApi from '@/services/AxiosApi';

export default {
    async getTrainings() {
        return AxiosApi.request({
            url: '/api/raas-context',
            method: 'get'
        }).then((response) => {
            return response.data;
        });
    }
}