import axios from 'axios'

const AxiosApi = axios.create({
    // eslint-disable-next-line
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/vnd.api+json',
    },
});

export default AxiosApi
