<template>
  <v-dialog v-model="_isVisible" max-width="360">
    <v-card>
      <v-card-title class="headline">Wachtwoord vergeten?</v-card-title>
      <v-card-text>
        Vul je email adres in om je wachtwoord te kunnen resetten. Je ontvangt een nieuw wachtwoord per mail.

        <v-container>
          <v-row>
            <v-text-field v-if="!isPasswordForgetFormSubmitted" :label="$t('login.input-field-email')" v-model="email" required :disabled="isSubmittingPasswordForgetForm"></v-text-field>
            <p v-else class="well well-info">{{ $t('login.password-reset-confirmation', { email: email }) }}</p>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="!isPasswordForgetFormSubmitted">
        <v-btn text @click="hide()" :disabled="isSubmittingPasswordForgetForm">{{ $t('login.perform-password-reset-cancel-action-label') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="onPasswordResetClick()" :disabled="isSubmittingPasswordForgetForm">{{ $t('login.perform-password-reset-action-label') }}</v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-btn color="primary" text @click="hide()" block>{{ $t('login.close-password-reset-window-label' )}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AxiosApi from '@/services/AxiosApi';

export default {
  name: 'PasswordResetModal',

  props: {
    isVisible: Boolean
  },

  data: () => ({
    email: null,
    isSubmittingPasswordForgetForm: false,
    isPasswordForgetFormSubmitted: false,
  }),

  computed: {
    _isVisible: {
      get () {
        return this.isVisible;
      },
      set (visible) {
        if (!visible) {
          this.hide();
        }
      }
    }
  },

  methods: {
    hide () {
      this.$emit('onClose');
    },
    onPasswordResetClick () {
      this.isSubmittingPasswordForgetForm = true;

      AxiosApi.request({
        url: 'reset-password',
        method: 'post',
        data: {
          email: this.email,
        }
      }).then(() => {
        this.isPasswordForgetFormSubmitted = true;
      }).finally(() => {
        this.isPasswordForgetFormSubmitted = true;
        this.isSubmittingPasswordForgetForm = false;
      });
    }
  }
}
</script>