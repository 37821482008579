<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        {{ $t('global.region') }}: {{ $t('regions.' + region) }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="regionCode in regions" :key="regionCode" @click="onSelect(regionCode)">
        <v-list-item-title>{{ $t('regions.' + regionCode) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'RegionSelector',

  props: {
    region: String
  },

  data: () => ({
    regions: [
      'nl', 'be'
    ]
  }),

  methods: {
    onSelect(regionCode) {
      this.$emit('onSelect', regionCode);
    },
  }
}
</script>