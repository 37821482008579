<template>
    <v-hover v-slot:default="{ hover }">
        <v-card :elevation="hover ? 4 : 2" v-bind:class="`my-2 card-training ${!training.isAvailable?'semi-disabled':''}`">
            <span class="available-soon" v-if="!training.isAvailable">{{ $t('training.available-soon') }}</span>
            <v-img height="200" v-bind:src="training.thumb"></v-img>
            <v-card-title>
                {{training.title}}
                <v-icon right v-if="training.isFinished" class="primary--text" :title="$t('training.training-finished-message')">mdi-check-circle</v-icon>
            </v-card-title>
            <v-card-subtitle>{{training.description}}</v-card-subtitle>
            <v-card-actions>
                <v-btn v-if="!training.isAvailable" disabled text block><v-icon left>mdi-clock</v-icon> {{ $t('training.available-soon') }}</v-btn>
                <v-btn v-else-if="!training.isFinished" text block color="primary" v-bind:href="training.link + '?token=' + token"><v-icon left>mdi-play</v-icon> {{ $t('training.start-training') }}</v-btn>
                <v-btn v-else text block color="accent" v-bind:href="training.link + '?token=' + token"><v-icon left>mdi-refresh</v-icon> {{ $t('training.try-again') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-hover>
</template>

<script>
    export default {
        name: 'TrainingCard',

        props: {
            training: {
                type: Object,
                required: true,
            },
            token: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style scoped>
    .card-training {
        overflow: hidden;
    }

    .card-training.semi-disabled > *:not(.v-card__progress) {
        opacity: 0.6;
        transition: inherit;
    }

    .available-soon {
        position: absolute;
        background-color: #1c2341;
        color: #fff;
        font-size: 18px;
        transform: rotate(-30deg);
        padding: 5px;
        width: 400px;
        text-align: center;
        opacity: 1 !important;
        z-index: 1;
        left: -90px;
        top: 50px;
        box-shadow: 0 2px 2px rgba(50, 50, 50, 0.3);
    }

    @media only screen and (min-width: 600px) {
        .card-training {
            padding-bottom: 32px !important;
        }
        
        .v-card__actions {
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
</style>