<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        {{ $t('global.language') }}: {{ $t('languages.' + language) }}
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="isoCode in languageIsoCodes" :key="isoCode" @click="onSelect(isoCode)">
        <v-list-item-title>{{ $t('languages.' + isoCode) }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'LanguageSelector',

  props: {
    language: String
  },

  data: () => ({
    languageIsoCodes: [
      'nl', 'be', 'fr', 'en'
    ]
  }),

  methods: {
    onSelect(isoCode) {
      this.$emit('onSelect', isoCode);
    },
  }
}
</script>